import Base, { ModelAPIIndexMeta, ModelAPIIndexParams } from './Base';
import Product, { ProductAttrs } from './Product';
import Variant, { VariantAttrs } from './Variant';

export interface CartAttrs {
  productId: number;
  personId: number;
  variantId: number;
  createdAt: string;
  updatedAt: string;
  Product: ProductAttrs;
  Variant: VariantAttrs;
}

export default class Cart extends Base<CartAttrs> {
  static async fetch(params?: Partial<CartAttrs> & ModelAPIIndexParams) {
    const response = await this.$api.get<{
      data: CartAttrs[];
      meta: ModelAPIIndexMeta;
    }>('v1/carts', { params });
    const data = response.data.data;
    const meta = response.data.meta;

    return { data, meta };
  }

  product$: Product;
  variant$: Variant;

  constructor(attrs: CartAttrs) {
    super(attrs);

    this.product$ = new Product(attrs.Product);
    this.variant$ = new Variant(attrs.Variant);
  }

  remove() {
    return Cart.$api.delete(`/v1/carts/${this.data.productId}`);
  }
}
