<script setup lang="ts">
import sumBy from 'lodash/sumBy';
import Cart from '~~/utils/models/Cart';
import LineHorizontalGradient2 from '~~/assets/svg/lines/horizontal-gradient-2.svg';

const { data } = await useAsyncData(() => Cart.fetch());
const total = computed(() => sumBy(data.value?.data, 'Product.coins'));

definePageMeta({ middleware: ['logged-in'] });
useHead({ title: 'My Cart' });
</script>

<template>
  <main class="pt-80 lg:pt-[112px]">
    <div class="container">
      <h1 class="text-32 lg:text-48 leading-40 lg:leading-64">My Cart</h1>
      <p class="opacity-75 mb-8">Here are your cart items</p>
      <LineHorizontalGradient2 class="mb-32" />

      <div
        class="grid lg:grid-cols-2 items-start gap-16 lg:gap-32"
        v-if="data?.data.length"
      >
        <LazyCartList :carts="data.data" />
        <div>
          <LazyCartInfo class="mb-16 lg:mb-32" :carts="data.data" />
          <LazyOrderForm v-if="$auth.person?.coinsAvailable! >= total" />
        </div>
      </div>
      <div v-else>
        <p class="mb-32">Your cart is empty. Please add a few items.</p>
        <RouterLink class="btn btn-primary btn-165x48" to="/shvrcoin">
          Find merch
        </RouterLink>
      </div>
    </div>
  </main>
</template>
